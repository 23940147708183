<template>
    <v-container fluid class="my-auto">
        <!-- SCREEN TITLE -->
        <v-row justify="center" align="center" class="py-4 py-lg-16">
            <v-col style="text-align: center">
                <p class="customTitle pt-0 pt-lg-6" style="color: #FE7700">{{ contactMsg[0]['title'] }}</p>
            </v-col>
        </v-row>

        <!-- CONTACT INTRO TEXT -->
        <v-row justify="center" align="center" style="background: #647ce0" class="py-4 py-lg-16">
            <v-col cols="auto" style="text-align: center;" class="px-0 px-lg-16">
                <!-- SPANISH CONTACT INTRO TEXT -->
                <p class="color_text contactIntroText" v-if="this.language === 'es'">
                    {{ contactMsg[0]['msg1'] }}
                </p>

                <!-- ENGLISH CONTACT INTRO TEXT -->
                <p class="color_text contactIntroText" v-if="this.language === 'en'">
                    {{ contactMsg[0]['msg1'] }}
                </p>
            </v-col>
        </v-row>

        <!-- CONTACT FORM SECTION -->
        <v-row class="pt-16" style="background: #464646;" justify="center">
            <!-- REGULAR TEXT FIELDS LEFT COLUMN -->
            <v-col class="mx-10" xs="12" sm="12" md="5">
                <!-- FULL NAME TEXT FIELD -->
                <v-row>
                    <v-col>
                        <h2>1. {{ contactMsg[0]['form1'] }}</h2>
                        <h3> &#42;</h3>
                        <v-text-field
                            :placeholder="contactMsg[0]['placeholder1']"
                            @keyup.enter.exact="sendFormRequest"
                            v-model="name"
                            color="blue"
                            dark
                        ></v-text-field>
                    </v-col>
                </v-row>

                <!-- EMAIL TEXT FIELD -->
                <v-row dense>
                    <v-col>
                        <h2>2. {{ contactMsg[0]['form2'] }}</h2>
                        <h3> &#42;</h3>
                        <v-text-field
                            :placeholder="contactMsg[0]['placeholder2']"
                            v-model="email"
                            color="blue"
                            type="email"
                            dark
                        ></v-text-field>
                    </v-col>
                </v-row>

                <!-- PHONE TEXT FIELD -->
                <v-row dense>
                    <v-col>
                        <h2>3. {{ contactMsg[0]['form3'] }}</h2>
                        <h3> &#42;</h3>
                        <v-text-field
                            :placeholder="contactMsg[0]['placeholder3']"
                            @keyup.enter.exact="sendFormRequest"
                            v-model="phone"
                            color="blue"
                            dark
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>

            <!-- BIG TEXT AREA RIGHT COLUMN -->
            <v-col class="mx-10" xs="12" sm="12" md="5">
                <!-- DESCRIPTION TEXT AREA -->
                <v-row>
                    <v-col>
                        <h2>4. {{ contactMsg[0]['form4'] }}</h2>
                        <h3> &#42;</h3>

                        <v-textarea
                            style="padding-left: 10%; padding-right: 10%;"
                            :placeholder="contactMsg[0]['placeholder4']"
                            class="custom-placeholder-color"
                            @keyup.enter.exact="sendFormRequest"
                            v-model="message"
                            color="blue"
                            outlined
                            dark
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>

        <!-- SUBMIT BUTTON SECTION -->
        <v-row style="background: #464646;" align="start" class="pb-10">
            <!-- REQUIRED FIELDS LABEL -->
            <v-col order="2" order-lg="1" cols="12" md="5" align-self="center" align="center">
                <h3> &nbsp;&#42;&nbsp;</h3><h4 style="color: white; font-style: italic; opacity: 60%">{{
                    contactMsg[0]['form_important_fields']
                }}</h4>
            </v-col>

            <!-- SUBMIT FORM BUTTON -->
            <v-col order="3" order-lg="2" cols="12" md="2" align-self="center" align="center">
                <Button
                    :name="contactMsg[0]['btn1']"
                    :color="contactMsg[0]['colorBtn1']"
                    :w="contactMsg[0]['wButtons']"
                    :usage-function="sendFormRequest"
                ></Button>
            </v-col>

            <!-- ACCEPT PRIVACY POLICY CHECKBOX -->
            <v-col order="1" order-lg="3" cols="12" md="5" align-self="center" align="center">
                <v-container class="px-8">
                    <!-- ACCEPT PRIVACY POLICY LABEL -->
                    <h2 style="padding-left: 0">{{ contactMsg[0]['form_privacy_p1'] }}</h2>

                    <h3> &nbsp; &#42;</h3>

                    <br>

                    <!-- ACCEPT PRIVACY POLICY CHECKBOX -->
                    <input type="checkbox" id="contact_checkbox" v-model="privacyPolicyAccepted">&nbsp;

                    <!-- PRIVACY POLICY URL -->
                    <!-- TODO Set Up Correct Privacy Policy URL -->
                    <a
                        href="/"
                        id="contact_checkbox"
                        style="font-family: 'Montserrat', sans-serif; color: #647ce0; font-size: 16px;"
                    >{{ contactMsg[0]['form_privacy_p2'] }}</a>

                </v-container>
            </v-col>
        </v-row>

        <!-- SEND CV SECTION -->
        <v-row style="background: #647ce0;" class="py-16 px-2" justify="center" align="center">
            <v-col cols="auto" style="text-align: center;">
                <!-- CAREERS INVITATION LABEL -->
                <h2 class="color_text" style="padding-left: 0; padding-right: 0; font-size: 1.3rem">
                    {{ contactMsg[0]['msg2'] }}
                </h2>

                <!-- SEND CV BUTTON -->
                <v-row class="d-flex justify-center mt-10 mb-12">
                    <Button
                        :name="contactMsg[0]['btn2']"
                        :color="contactMsg[0]['colorBtn2']"
                        :w="contactMsg[0]['wButtons']"
                        :usage-function="sendCV"
                    ></Button>
                </v-row>

            </v-col>
        </v-row>

        <!-- FORM ERROR BOTTOM SHEET -->
        <v-bottom-sheet v-model="bottomSheetTrigger">
            <v-sheet
                class="text-center"
                height="150px"
            >
                <v-btn
                    class="mt-6"
                    color="primary"
                    text
                    @click="bottomSheetTrigger = !bottomSheetTrigger"
                >
                    CERRAR
                </v-btn>
                <div class="py-3">
                    {{ bottomSheetMessage }}
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import * as db from "./../../constants/contact_database"
import Button from '@/components/buttons/Button.vue'
import axios from "axios"

export default {
    name: "Contact_Screen",
    components: {
        Button
    },
    data() {
        return {
            name: "",
            email: "",
            phone: "",
            message: "",
            contactMsg: null,
            bottomSheetMessage: "",
            bottomSheetTrigger: false,
            privacyPolicyAccepted: false,
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        },
    },
    beforeMount() {
        //Checks store value to import localized text
        if (this.language === 'es') {
            this.contactMsg = db.msg_es;
        } else {
            this.contactMsg = db.msg_en;
        }
    },
    methods: {
        completeFieldsValidation() {
            return this.name !== "" && this.email !== "" && this.phone !== "" && this.message !== "";
        },

        validatePhone() {
            const pattern = /^\D*(\d\D*){10}$/;
            return pattern.test(this.phone)
        },

        validateEmail() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.email)
        },

        async sendFormRequest() {
            if (this.completeFieldsValidation() === true) {

                if(this.validatePhone() === true) {

                    if(this.validateEmail() === true) {

                        if(this.privacyPolicyAccepted === true) {
                            const requestBody = {
                                'name': this.name,
                                'email': this.email,
                                'phone': this.phone,
                                'message': this.message
                            };

                            // await axios.post('http://localhost:5050/ideenwebsite/us-central1/sendContactFormToSales', requestBody); // DEVELOPMENT URL
                            await axios.post('https://us-central1-ideenwebsite.cloudfunctions.net/sendContactFormToSales', requestBody); // LIVE URL

                            // Display thank you message
                            this.bottomSheetTrigger = true;
                            this.bottomSheetMessage = "¡Gracias por compartirnos tus datos! Nos pondremos en contacto contigo muy pronto.";

                            this.clearForm();

                        } else {
                            // Missing terms acceptance message
                            this.bottomSheetTrigger = true;
                            this.bottomSheetMessage = "Favor de aceptar nuestro Aviso de Privacidad";
                        }

                    } else {
                        // Bad email message
                        this.bottomSheetTrigger = true;
                        this.bottomSheetMessage = "Por favor introduce un email válido";
                    }

                } else {
                    // Bad phone message
                    this.bottomSheetTrigger = true
                    this.bottomSheetMessage = "Por favor introduce un teléfono válido de 10 dígitos";
                }

            } else {
                // Incomplete form message
                this.bottomSheetTrigger = true;
                this.bottomSheetMessage = "Favor de llenar todos los campos";
            }
        },

        clearForm() {
            this.name = "";
            this.phone = "";
            this.email = "";
            this.message = "";
            this.privacyPolicyAccepted = false;
        },

        sendCV() {
            window.open("mailto:vacantes@ideen.com");
        }
    }
}
</script>

<style scoped>
.color_text {
    color: white;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
}

h2 {
    display: inline;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 1rem;
    padding-left: 10%;
}

h3 {
    font-family: 'Montserrat', sans-serif;
    color: red;
    text-align: center;
    display: inline;
    font-size: 1rem;

}

h4 {
    font-family: 'Montserrat', sans-serif;
    color: gray;
    display: inline;
    font-size: 1rem;
}

/deep/ .v-text-field {
    width: 40rem;
    padding-left: 10%;
    padding-right: 10%;
}

.v-text-field--outlined >>> fieldset {
    border-color: rgba(100, 124, 224, 1);
}

::placeholder {
    color: #7d7d7d !important;
    opacity: 1;
}

.contactIntroText {
    margin: 0 20%;
    font-weight: bold;
}

@media (max-width: 768px) {
    .contactIntroText {
        margin: 3% 5%;
        font-weight: bold;
    }
}
</style>
