export const msg_es = [
    {
        title:"CONTÁCTANOS",
        msg1: "Tu proyecto es único y nos interesa, al compartirnos tus datos te asignamos un ejecutivo quien estará dando seguimiento a tu proyecto.",
        msg2: "O si lo quieres es unirte a nuestro equipo",
        form1: "Nombre",
        placeholder1: "Nombre",
        form2: "eMail",
        placeholder2: "mail@ejemplo.com",
        form3: "Teléfono",
        placeholder3: "55 5555 5555",
        form4: "Mensaje",
        placeholder4: "¿En qué podemos ayudarte?",
        form_important_fields: "Campos Obligatorios",
        form_privacy_p1: "Te pedimos de favor, revises y aceptes nuestro",
        form_privacy_p2: " Aviso de Privacidad",
        btn1: "Enviar",
        btn2: "Enviar CV",
        colorBtn1: "#3f63d9",
        colorBtn2: "#fe7700",
        wButtons: "150px",
    }
]

export const msg_en = [
    {
        title:"Thanks for reaching us",
        msg1: "We consider your project as a unique one and we are interested in getting to know more, if you share your contact info with us an account manager will be reaching you to follow up on your project"
        ,
        msg2: "Maybe you want to work with us",
        form1: "Full Name",
        placeholder1: " Full Name",
        form2: "eMail",
        placeholder2: "mail@ejemplo.com",
        form3: "Phone",
        placeholder3: "5624301121",
        form4: "Message",
        placeholder4: "How can we help you?",
        form_important_fields: "Required",
        form_privacy_p1: "We ask you to read and accept our privacy policy",
        form_privacy_p2: "Privacy Policy",
        btn1: "SEND",
        btn2: "SEND CV",
        colorBtn1: "#3f63d9",
        colorBtn2: "#fe7700",
        wButtons: "150px",
    }
]

